<template>
  <div class="world login">
    <div class="login-wrapper">
      <div class="title">
        <h1>{{ $t('Wilms World - Profiel') }}</h1>
      </div>

      <!--      <div class="message error-message" v-if="message">{{ message }}</div>-->

      <formulate-form v-if="user" ref="profileForm" name="profileForm" v-model="user.profile" @submit="submit">
        <formulate-input validation="required" name="first_name" type="text" :label="$t('Voornaam')"></formulate-input>
        <formulate-input validation="required" name="last_name" type="text" :label="$t('Achternaam')"></formulate-input>
        <formulate-input :options="activeLocalesFront" name="language" type="select" :label="$t('Taal')"></formulate-input>

        <formulate-input v-model="currentPassword" :validation="passwordValidation" name="current_password" type="password" :label="$t('Oud wachtwoord')"></formulate-input>
        <formulate-input v-model="password" :validation="passwordValidation" name="new_password" type="password" :label="$t('Wachtwoord')"></formulate-input>
        <formulate-input v-model="passwordRepeat" :validation="passwordValidation + '|confirm:new_password'" name="new_password_verify" type="password" :label="$t('Wachtwoord herhalen')"></formulate-input>

        <formulate-input type="submit" :label="isLoading ? $t('Even geduld...') : $t('Bewaar')"></formulate-input>
      </formulate-form>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'

export default {
  data () {
    return {
      user: null,
      isLoading: false,
      currentPassword: '',
      password: '',
      passwordRepeat: '',
      errors: false
    }
  },
  mounted () {
    this.user = JSON.parse(JSON.stringify(this.$store.getters.getCurrentUser))
  },
  methods: {
    async submit () {
      this.isLoading = true

      delete this.user.profile.current_password
      delete this.user.profile.new_password
      delete this.user.profile.new_password_verify

      if (this.passwordValidation === 'required') {
        await ApiService.savePassword({
          current_password: this.currentPassword,
          new_password: this.password,
          new_password_verify: this.passwordRepeat
        }).then(res => {
          if (res.status === 200) {
            this.errors = false
          } else if (res.response.status === 406) {
            this.errors = true
            notification(this.$t(res.response.data.detail), 'error')
          }
        })
      }

      if (!this.errors) {
        const response = await ApiService.saveProfile(this.user.profile)

        if (response.status === 200) {
          await this.$store.dispatch('fetchCurrentUser')
          notification(this.$t('Profiel succesvol aangepast!'))
          await this.$router.push({ name: 'ww.dashboard' })
        }
      }

      this.isLoading = false
    }
  },
  computed: {
    passwordValidation () {
      if (this.currentPassword !== '' || this.password !== '' || this.passwordRepeat !== '') {
        return 'required'
      }

      return ''
    }
  }
}
</script>
